@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Regular.woff2') format('woff2'),
    url('./assets/fonts/Satoshi-Regular.woff') format('woff'),
    url('./assets/fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Bold.woff2') format('woff2'),
    url('./assets/fonts/Satoshi-Bold.woff') format('woff'),
    url('./assets/fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}