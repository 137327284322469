.content-menu {
    background: #F8F9FA;
    border: 1px solid #C2C7D0;
    padding-top: 20px;
}

.menu-option {
    background: none;
    border: none;
    padding: 7px 10px;
    padding-bottom: 10px;
    transform: translateY(1px);
    color: #4f4f4f;
    font-size: 0.95rem;
}

.menu-option:hover {
    color: #1d2228;
}

.menu-active {
    background-color: white;
    border: 1px solid #C2C7D0;
    border-bottom: 1px solid white;
    color: #212428;
}

.btn-test {
    background: linear-gradient(to bottom, #1F2122, #424A51);
    padding: 10px 25px;
    font-size: 0.9rem;
    border: 1px solid rgb(133, 133, 133);
    color: white;
    border-radius: 2px;
}

.btn-submit {
    background: #135181;
    color: white;
    padding: 7px 15px;
    border: none;
    font-size: 0.9rem;
    border-radius: 3px;
}

.item-c {
    padding: 20px;
    border: 1px solid rgb(225, 225, 225);
    border-radius: 3px;
}

.item-challenge {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-challenge {
    background: #135181;
    color: white;
    padding: 7px 12px;
    border: none;
    font-size: 0.9rem;
    border-radius: 3px;
    outline: none;
}

.btn-challenge-solved {
    background: #e6e6e6;
    color: black;
    border: 1px solid gray;
}

.btn-view-challenges {
    background: #135181;
    color: white;
    padding: 7px 15px;
    border: none;
    font-size: 0.9rem;
    border-radius: 2px;
}

.btn-c {
    background: none;
    border: none;
}

.progress-bar {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    width: 100%;
    font-size: 0.85rem;
    border: 1px solid #D3D3D3;
}

.message-empty {
    border: 2px dotted #9e9e9e;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    color: #8f8f8f;
}

.tab-confirm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.tab-confirm>div {
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.tab-confirm .btn-accept {
    padding: 5px 15px;
    background-color: #135181;
    border: none;
    border-radius: 3px;
    color: white;
}

.tab-confirm .btn-cancel {
    padding: 5px 12px;
    background-color: #ededed;
    border: 1px solid gray;
    border-radius: 3px;
}

.btn-send {
    width: 100%;
    padding: 10px 0px;
    color: white;
    background: rgb(47, 47, 47);
    border: 1px solid rgb(0, 98, 16);
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.btn-send:hover {
    background-color: rgb(59, 59, 59);
}

.caja {
    width: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.puesto {
    height: 60px;
    background-color: #3f3f3f;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
}

.points {
    width: 100%;
    color: white;
    background: #0D6DB9;
    padding: 2px 0px;
    text-align: center;
    font-weight: bold;
    margin-top: 2px;
    border-radius: 2px;
}

.foto {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    padding: 5px;
}

.foto img {
    height: 80px;
    width: 100%;
    object-fit: contain;
}

.name-resume {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-skip {
    position: absolute;
    bottom: 0;
    right: 15px;
    border: none;
    background: none;
    font-size: 0.8rem;
}