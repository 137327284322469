.profile-img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.btn-profile {
    background: none;
    border: none;
}

.content-dash {
    background: white;
    border: 1px solid #EBEBF3;
    padding: 30px 20px;
    border-radius: 20px;
}

.btn-continue {
    background: none;
    border: 1px solid rgb(221, 221, 221);
    padding: 5px 10px;
    border-radius: 5px;
}

.btn-join {
    color: white;
    background: none;
    border: none;
    padding: 5px 10px;
    transition: transform 0.3s ease;
}

.btn-join:hover {
    transform: translateY(-2px);
}

.btn-view-all {
    cursor: pointer;
}

.btn-view-all:hover {
    text-decoration: underline;
}

.card-menu {
    width: 200px;
    position: fixed;
    background-color: #3D4450;
    text-align: start;
    font-size: 0.85rem;
    transform: translateX(-100px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    z-index: 10;
}

.card-menu>div {
    padding: 8px 15px;
    cursor: pointer;
}

.card-menu div:hover {
    background-color: #DCDEDF;
    color: rgb(45, 45, 45);
}

.inter {
    font-family: 'Satoshi', sans-serif;
}

.cond {
    font-family: "Roboto Condensed", sans-serif;
}

.btn-main {
    background-color: #135181;
    padding: 7px 15px;
    border: none;
    color: white;
}

.bar-wait {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 1px solid #EBEBF3;
    margin: 10px 0px;
    border-radius: 5px;
}

.btn-add-user {
    background-color: rgb(42, 42, 42);
    color: white;
    border: none;
    padding: 8px 15px;
}

.tab-friends {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
}

.tab-friends>div {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.btn-t {
    padding: 7px 20px;
    border: 1px solid rgb(118, 118, 118);
    background: none;
    color: white;
    border-radius: 3px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.btn-t:hover {
    background-color: white;
    color: black;
}

.menu {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;
    cursor: pointer;
}

.menu li {
    padding: 5px 0px;
    padding-right: 15px;
}

.tab-noti {
    position: absolute;
    background-color: white;
    width: 450px;
    transform: translateX(-380px);
    border-bottom: 1px solid rgb(193, 193, 193);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    z-index: 11;
}

.item-r {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    border-bottom: 1px solid rgb(193, 193, 193);
}

.i-c {
    cursor: pointer;
}

.i-c:hover span {
    text-decoration: underline;
}

.badget {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    position: absolute;
    transform: translate(10px, -11px);
}

.btn-noti {
    cursor: pointer;
}

.btn-noti:hover {
    filter: brightness(0.9);
}

.tab-test {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.tab-test>div {
    background-color: white;
    padding: 25px;
    width: 500px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.tab-test button {
    width: 100%;
    background: rgb(47, 47, 47);
    border: none;
    color: white;
    padding: 12px 0;
    border-radius: 3px;
}

.bar-progress {
    height: 50px;
    background-color: #F5F5F5;
    border-radius: 5px;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.bar-progress>div {
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(76, 110, 184);
    transition: width 0.3s ease;
}

.btn-view-sends {
    background: rgb(76, 110, 184);
    border: none;
    color: white;
    padding: 5px 7px;
    border-radius: 3px;
}

.btn-request {
    border: none;
    background: rgb(25, 52, 81);
    color: white;
    padding: 3px 10px;
    border-radius: 3px;
}

.tab-loading {
    background: rgb(8, 73, 31);
    color: white;
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 0.9rem;
    margin-bottom: 20px;
}

.box-dashboard {
    display: grid;
    grid-template-columns: 75% 25%;
}

@media screen and (max-width:720px) {
    .menu {
        display: none;
    }

    .box-dashboard {
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;
    }
}