  .contenedor {
      position: relative;
      width: 100%;
      height: 100vh;
      overflow: hidden;
  }

  .contenedor video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -1;
      transform: translate(-50%, -50%);
      object-fit: cover;
  }

  nav {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
  }

  nav ul {
      display: flex;
      align-items: center;
  }

  nav li {
      margin-left: 25px;
      list-style: none;
  }

  .ancla {
      color: white;
      text-decoration: none;
      transition: text-decoration 3s ease;
  }

  .ancla:hover {
      text-decoration: underline;
      color: white;
  }

  .btn-r {
      border: 1px solid white;
      color: white;
      padding: 10px 20px;
      background-image: linear-gradient(to right, #0477E4, #178CFA);
      border: none;
      font-size: 0.9rem;
      transition: background-color 0.3s ease;
  }

  .btn-explore {
      border: 1px solid white;
      color: white;
      padding: 11px 25px;
      background-image: linear-gradient(to right, #0477E4, #178CFA);
      border: none;
      font-size: 0.9rem;
      transition: background-color 0.3s ease;
  }

  .btn-i {
      font-size: 0.9rem;
      background-color: #262A33;
      padding: 10px 30px;
      color: white;
      border: none;
  }

  .btn-register {
      font-size: 0.9rem;
      background-color: #262A33;
      padding: 10px 20px;
      color: white;
      border: none;
  }

  .btn-l:hover {
      filter: brightness(1.1)
  }

  .info {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
  }

  .title-info {
      width: 70%;
  }

  .title-main {
      font-size: 4rem;
      width: 60%;
  }

  .info-main {
      display: flex;
      flex-direction: column;
      color: white;
      height: 100%;
      justify-content: center;
      align-items: center;
  }

  .header {
      background-image: linear-gradient(to bottom, black, #1F2229);
  }

  .item-friend {
      display: flex;
      border-bottom: 1px solid rgb(215, 215, 215);
      justify-content: space-between;
      color: black;
  }

  .item-friend button {
      border-radius: 3px;
  }

  .btn-add-use {
      background-color: rgb(40, 40, 40);
      color: white;
      padding: 7px 15px;
      border: none;
      border-radius: 4px;
      transition: filter 0.2s ease;
  }

  .btn-add-use:hover {
      filter: brightness(1.3);
  }

  @media screen and (max-width:720px) {

      .info {
          display: flex;
          flex-direction: column;
      }

      .title-info {
          width: 100%;
      }

      .title-main {
          font-size: 2rem;
          width: 100%;
      }

      .description-main {
          text-align: center;
      }

      .menu-home {
          display: none;
      }
  }