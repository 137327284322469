.room-item {
    border: 1px solid rgb(234, 234, 234);
    padding: 20px;
    border-radius: 5px;
}

.avatar {
    width: 100px;
    height: 100px;
    border: 1px solid #676767;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.avatar:hover {
    background-color: rgb(252, 252, 252);
}

.btn-add {
    width: 60px;
    height: 60px;
    background-color: #1b1b1b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 3rem;
    cursor: pointer;
    transition: border 0.3s ease;
}

.btn-add:hover {
    border: 1px solid white;
}

.btn-invite {
    padding: 7px 12px;
    border: 1px solid rgb(228, 228, 228);
    border-radius: 5px;
    background: none;
}

.inp-chat {
    display: flex;
    align-items: center;
    background-color: black;
    padding: 3px 10px;
}

.inp-chat input {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    padding: 7px 10px;
    color: white;
}

.inp-chat button {
    padding: 3px 15px;
    border: none;
    color: white;
    background-color: rgb(39, 39, 39);
}

.btn-status {
    width: 100%;
    padding: 7px 0px;
    background-color: black;
    border: none;
    border-radius: 3px;
    color: white;
}

.btn-ready {
    width: 100%;
    padding: 15px 0px;
    border: none;
    background: linear-gradient(to bottom, #233760, #3B598D);
    color: white;
}

button:hover {
    filter: brightness(1.1);
}

.btn-view {
    padding: 7px 10px;
    background-color: black;
    border: none;
    border-radius: 3px;
    color: white;
}

.btn-try {
    padding: 7px 10px;
    border: 1px solid gray;
    border-radius: 3px;
    background: none;
}

.inp-editor {
    width: 100%;
    outline: none;
    border: 1px solid rgb(206, 206, 206);
    padding: 10px;
    border-radius: 3px;
}

.tab-req {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}



.tab-accept {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
}

.tab-accept>div {
    width: 350px;
    padding: 30px 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to top right, #141718, #262C2D);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    margin-top: 20px;
}



.tab-req>div {
    padding: 30px 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to top right, #141718, #262C2D);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}



.card-add input {
    width: 100%;
    background-color: #000000;
    border: 1px solid gray;
    padding: 10px 10px;
    outline: none;
    color: rgb(191, 191, 191);
}

.card-add button {
    background: linear-gradient(to bottom, #25272A, #3E464B);
    border: none;
    padding: 10px 0px;
    color: white;
    border: 1px solid rgb(128, 128, 128);
}

.btn-leave {
    width: 100%;
    background: linear-gradient(to bottom, #25272A, #3E464B);
    padding: 7px 0px;
    color: white;
    border: 1px solid rgb(128, 128, 128);
}

.btn-start {
    background: linear-gradient(to bottom, #102837, #1A4F6D);
    border: 1px solid rgb(133, 133, 133);
    padding: 15px 0px;
    width: 100%;
    color: white;
}

.content-friends {
    padding: 10px;
    background: white;
    border-radius: 10px;
    border: 1px solid #B4B4BB;
}

.input-search {
    display: flex;
    align-items: center;
    border: 1px solid gray;
    width: 100%;
}

.input-search input {
    border: none;
    outline: none;
    width: 100%;
    background: none;
    padding: 7px 10px;
}

.card-setting {
    background: white;
    padding: 25px 15px;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 5px;
    font-size: 1rem;
}

.card-chat {
    background: #171F25;
    border-radius: 3px;
}

.content-room {
    background: #F7F8FD;
}

.card-joins {
    background: white;
    padding: 20px;
    border: 1px solid #EBEBF3;
    border-radius: 10px;
}

.item-friend {
    display: flex;
    justify-content: space-between;
    padding: 7px 10px;
    color: rgb(207, 207, 207);
    cursor: pointer;
    border-radius: 3px;
}

.item-friend>button {
    background: none;
    border: none;
    color: white;
    transition: transform 0.3s ease;
}

.item-friend>button:hover {
    transform: translateY(-2px);
}

.list-friends {
    max-height: 400px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}

.tab-accept-room {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-accept-room>div {
    width: 500px;
    position: relative;
    box-shadow: 0px 0px 10px #4da9e7;

}

.tab-accept-room .info-c {
    background-color: #0B0C0D;
}

.tab-accept-room .options {
    background: radial-gradient(circle, #1E1F20, #151617);
    padding-top: 20px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
}

.tab-accept-room .btn-accept {
    background: linear-gradient(to right, #06BEFF, #2D74FF);
    color: white;
    border: none;
    padding: 10px 60px;
    border-radius: 2px;
    letter-spacing: 1px;
    font-weight: bold;
}

.tab-accept-room .btn-cancel {
    position: absolute;
    bottom: 0;
    right: 3px;
    background: none;
    border: none;
    color: #9b9b9b;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 0.5px;
}

.tab-settings {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-settings>form {
    width: 500px;
    background: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    border-radius: 3px;
}

.card-settings {
    display: grid;
    gap: 10px;
}

.tab-settings .btn-accept {
    background: linear-gradient(to bottom, #102837, #1A4F6D);
    font-size: 0.8rem;
    padding: 7px 30px;
    border: 1px solid rgb(133, 133, 133);
    color: white;
    letter-spacing: 0.5px;
}

.tab-settings .btn-cancel {
    background: linear-gradient(to bottom, #1F2122, #424A51);
    font-size: 0.8rem;
    padding: 7px 30px;
    border: 1px solid rgb(133, 133, 133);
    color: white;
    letter-spacing: 0.5px;
}

.form-item span {
    font-size: 0.9rem;
}

.form-item input {
    width: 100%;
    border: 1px solid #bababa;
    padding: 3px 10px;
    outline: none;
    border-radius: 3px;
}

.form-item select {
    width: 100%;
    width: 100%;
    background: white;
    border: 1px solid #bababa;
    padding: 5px 10px;
    outline: none;
    border-radius: 3px;
}

.btn-out {
    width: 100%;
    background: white;
    border: 1px solid rgb(103, 103, 103);
    padding: 12px 0px;
    border-radius: 5px;
    font-weight: bold;
    margin-bottom: 5px;
    transition: transform 0.3s ease;
}


.btn-out:hover {
    transform: translateY(-1px);
}

.btn-edit {
    background: none;
    border: none;
    font-weight: bold;
}

.item-tag {
    display: flex;
    align-items: center;
    background-color: #e9e9e9;
    padding: 3px 10px;
    border-radius: 20px;
    margin-left: 2px;
    margin-top: 2px;
    font-size: 13px;
}

.item-tag button {
    margin-left: 5px;
    background: none;
    border: none;
    background-color: #aaaaaa;
    border-radius: 50%;
    font-size: 12px;
}

.item-tag button:hover {
    background-color: #999999;
}

.input-tag {
    display: flex;
    border: 1px solid #BBBBBB;
    padding: 3px;
    flex-wrap: wrap;
    align-items: center;
    background-color: white;
}

.input-tag input {
    border: none;
    outline: none;
    background: none;
    width: 120px;
    font-size: 0.95rem;
}

.topics {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.topics span {
    background-color: rgb(239, 239, 239);
    padding: 2px 8px;
    border-radius: 20px;
    border: 1px solid rgb(197, 197, 197);
    cursor: pointer;
    font-size: 0.8rem;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
}

.box-room {
    display: grid;
    font-size: 0.9rem;
    grid-template-columns: 70% 30%;
}

@media screen and (max-width:720px) {
    .box-room {
        display: flex;
        flex-direction: column;
        padding-bottom: 80px;
    }

    .chat-room {
        display: none;
    }
}