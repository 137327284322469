.info-toast {
    font-size: 14px;
    padding: 20px 0px;
    text-align: center;
    position: fixed;
    margin-top: 20px;
    left: 0px;
    right: 0px;
    top: 0px;
    margin-left: auto;
    margin-right: auto;
    z-index: 102;
    width: 350px;
    background-color: #333333;
    color: white;
    /* animation: aparecer 0.3s normal; */
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.input-main {
    width: 100%;
    border: 1px solid #E4E4E7;
    outline: none;
    border-radius: 5px;
    padding: 7px 10px;
}

.input-main:focus {
    border: 1px solid #7c7c7c;
}

.login-content {
    background-image: linear-gradient(to bottom, black, #1E2228);
    background-size: cover;
    background-position: center;
    margin: 0;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}


.box-login {
    background: #212328;
    margin-top: 30px;
    padding: 20px 20px;
    border-radius: 3px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    position: relative;
}

.box-login input {
    width: 100%;
    background-color: #32353C;
    outline: none;
    border: none;
    padding: 7px 10px;
    color: #D7D7D7;
    border-radius: 2px;
}

.box-login input:hover {
    filter: brightness(1.1);
}

.btn-main {
    background: #2f2f2f;
    color: white;
    padding: 7px 0px;
    border: none;
}

.icon-load {
    animation: load 3s infinite linear;
}


.btn-login {
    background-image: linear-gradient(to right, #0477E4, #178CFA);
    color: white;
    width: 100%;
    border: none;
    padding: 10px 0px;
    border-radius: 2px;
}

.btn-back {
    background: none;
    border: none;
    color: white;
    font-size: 1rem;
}

@keyframes load {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}